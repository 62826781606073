
<template>
    <div class="page-cu-container">
        <div class="page-cu-top">
            <a-form layout="inline">
                <a-form-item>
                    <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
                </a-form-item>
                <a-form-item>
                    <a-range-picker :placeholder="['开始时间','结束时间']" style="width: 400px !important; " show-time format="YYYY-MM-DD HH:mm:ss" @change="getTime"></a-range-picker>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="searchList">搜索</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="page-cu-main">
            <a-table :columns="columns" :data-source="list" rowKey="id" :pagination="false" bordered></a-table>
        </div>
        <div class="page-cu-pagination">
            <a-pagination :page-size.sync="page.pageSize" :total="page.totalRow" v-model="page.start" @change="changePage" />
        </div>
    </div>
</template>

<script>
    import * as Api from './api'

    export default {
        name: "index",
        components:{},
        data(){
            return{
                key: '',
                time: [],
                columns: [
                    {
                        title: '日志名称',
                        dataIndex: 'admin_name',
                    },
                    {
                        title: '日志描述',
                        dataIndex: 'content',
                    },
                    {
                        title: '操作时间',
                        dataIndex: 'create_time',
                    }
                ],
                page:{
                    start: 1,
                    totalRow: 0,
                    pageSize: 0,
                    limit: 20,
                    key: '',
                    start_time: '',
                    end_time: ''
                },
                list:[]
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getTime(t, v){
               this.page.start_time = v[0];
               this.page.end_time = v[1];
            },
            searchList() {
                this.page.start = 1;
                this.getList();
            },
            async getList(){
                let res = await Api.List(this.page)
                this.page.totalRow = res.page.totalRow;
                this.page.pageSize = res.page.pageSize;
                this.list = res.page.list;
            },
            changePage(currentPage){
                this.page.start = currentPage;
                this.getList();
            },
        },
    }
</script>

<style scoped>

</style>
